import fetch from '../util/request';
// console.log(fetch);
export default {
    login(data) {
        return fetch({
            url: '/staff/login',
            method: 'post',
            data
        });
    },
    createCaptcha(params){
        return fetch({
            url: '/staff/createCaptcha',
            method: 'get',
            params
        });
    },
    getMyInfo(params) {
        return fetch({
            url: '/staff/myInfo',
            method: 'get',
            params
        });
    },
    logout(params) {
        return fetch({
            url: '/staff/logout',
            method: 'get',
            params
        });
    },
    reg(data) {
        return fetch({
            url: '/staff/reg',
            method: 'post',
            data
        });
    },
    pages(params) {
        return fetch({
            url: '/staff/pages',
            method: 'get',
            params
        });
    },
    update(data) {
        return fetch({
            url: '/staff/update',
            method: 'post',
            data
        });
    },
};
