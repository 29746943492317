import { createRouter , createWebHashHistory } from 'vue-router'
import routes from './route.js';
import apiStaff from '@/api/staff';
import store from '@/store'

const router = createRouter({
   history : createWebHashHistory(),
   routes
});

router.beforeEach(async (to, from, next) => {
    console.log('from route:', from);
    console.log('to route:', to);
    if (['login', 'reg'].includes(to.name)) {
        return next();
    }
    console.log(store);
    if (!window.myInfo) {
        await apiStaff.getMyInfo().then(res => {
            if (res.code === 10000) {
                window.myInfo = res.data;
            }
        })
    }
    if (!to.matched.length) {
        return next({name: '404'});
    } else if (!localStorage.getItem('UserLoginToken') && to.name !== 'login') {
        return next({name: 'login'});
    }
    next()
  })

export default router