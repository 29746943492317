window.dragConfig = {
    pageX: 0,
    pageY: 0,
    isDragging: false,

    wrapperLeft: 0,
    wrapperTop: 0,

    wrapper: null
}

// eslint-disable-next-line no-unused-vars
let firstTime = new Date().getTime()

document.addEventListener('mousedown', e => {
    try {
        // console.log(e.target)
        if (e.target.className === 'bfll-shopping-cart') {
            firstTime = new Date().getTime()
            // eslint-disable-next-line no-undef
            dragConfig.pageX = e.pageX;

            // eslint-disable-next-line no-undef
            dragConfig.pageY = e.pageY;
            // eslint-disable-next-line no-undef
            dragConfig.isDragging = true;
            // eslint-disable-next-line no-undef
            dragConfig.wrapper = e.target;
            e.target.setAttribute("flag", false)
            // eslint-disable-next-line no-undef
            dragConfig.wrapperLeft = dragConfig.wrapper.offsetLeft;
            // eslint-disable-next-line no-undef
            dragConfig.wrapperTop = dragConfig.wrapper.offsetTop;
        }
    } catch (error) {
        console.log('拖拽发生错误');
        console.log(error);
        // eslint-disable-next-line no-undef
        dragConfig.isDragging = false;
    }
});
document.addEventListener('mousemove', e => {
    let lastTime = new Date().getTime()
    if (lastTime - firstTime > 200) {
        e.target.setAttribute('flag', true)
    }
    try {
        // eslint-disable-next-line no-undef
        if (dragConfig.isDragging) {
            // eslint-disable-next-line no-undef
            // console.log(dragConfig.wrapperLeft)
            // eslint-disable-next-line no-undef
            const pageX = e.pageX;
            // console.log(pageX)
            const pageY = e.pageY;
            // eslint-disable-next-line no-undef
            //  console.log(dragConfig.wrapper.clientWidth)
            // eslint-disable-next-line no-undef
            let offsetRight = document.body.clientWidth - pageX - (dragConfig.wrapper.clientWidth / 2);
            // eslint-disable-next-line no-undef
            let offsetBottom = document.body.clientHeight - pageY - (dragConfig.wrapper.clientHeight / 2);
            if (offsetBottom <= 0) {
                offsetBottom = 0
                console.log('飘了是不是？害羞想你是不是要飞出底部')
                // eslint-disable-next-line no-undef
            } else if (offsetBottom >= document.body.clientHeight - dragConfig.wrapper.clientHeight) {
                // eslint-disable-next-line no-undef
                offsetBottom = document.body.clientHeight - dragConfig.wrapper.clientHeight
                console.log('飘了是不是？害羞想你是不是要飞出顶部')
            }
            if (offsetRight <= 0) {
                offsetRight = 0
                console.log('飘了是不是？害羞想你是不是要飞出右部')
                // eslint-disable-next-line no-undef
            } else if (offsetRight >= document.body.clientWidth - dragConfig.wrapper.clientWidth) {
                // eslint-disable-next-line no-undef
                offsetRight = document.body.clientWidth - dragConfig.wrapper.clientWidth
                console.log('飘了是不是？害羞想你是不是要飞出左边部')

            }
            // eslint-disable-next-line no-undef
            dragConfig.wrapper.style.right = offsetRight + 'px';
            // eslint-disable-next-line no-undef
            dragConfig.wrapper.style.bottom = offsetBottom + 'px';
        }
    } catch (error) {
        console.log('拖拽发生错误');
        console.log(error);
        // eslint-disable-next-line no-undef
        dragConfig.isDragging = false;
    }
});
document.addEventListener('mouseup', () => {
    // eslint-disable-next-line no-undef
    dragConfig.isDragging = false;
});