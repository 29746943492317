import { createApp } from "vue";
var dayjs = require('dayjs');
import App from "./App.vue";
import router from "./router";
import store from "./store";
import installElementPlus from "./plugins/element";
require('./util/drag.js')
const app = createApp(App);
installElementPlus(app);
app.config.globalProperties.$ELEMENT.size = "mini";
app.config.globalProperties.$filters = {
    formatTime(time, type) {
        if (!time) {
            return '';
        }
        if (type === 'date') {
            return dayjs(time).format('YYYY-MM-DD');
        } else if (type === 'time') {
            return dayjs(time).format('HH:mm:ss');
        }
        return dayjs(time).format('YYYY-MM-DD HH:mm:ss');
    }
}

window.printParams = {
    LQ1600K: {
        PRINT_PAGESIZE: {
            intOrient: 0,
            intPageWidth: 2200,
            intPageHeight: 1397
        },
        PRINT_HTM: {
            intTop: 0,
            intLeft: 50,
            intWidth: '95%',
            intHeight: '100%'
        }
    },
    LQ595K: {
        PRINT_PAGESIZE: {
            intOrient: 0,
            intPageWidth: 2600,
            intPageHeight: 1397
        },
        PRINT_HTM: {
            intTop: 0,
            intLeft: 0,
            intWidth: '100%',
            intHeight: '100%'
        }
    }
}

let printerModel = localStorage.getItem('PRINTER_MODEL');
if (!printerModel) {
    printerModel = 'LQ595K';
    localStorage.setItem('PRINTER_MODEL', printerModel);
}
window.printParam = window.printParams[printerModel];
app.config.globalProperties.$baseURL = window.vars.baseURL;

app.use(store).use(router).mount("#app");
