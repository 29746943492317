const layout = () => import('@/layout/index.vue');
const routes = [
    {
        path: '/',
        name: 'empty',
        redirect: { name: 'index' }
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: '登录'
        },
        component: () => import(/* webpackChunkName: "login" */'../views/login.vue')
    },
    {
        path: '/reg',
        name: 'reg',
        meta: {
            title: '登录'
        },
        component: () => import(/* webpackChunkName: "login" */'../views/reg.vue')
    },
    {
        path: '/404',
        name: '404',
        meta: {
            title: '找不到页面'
        },
        component: () => import(/* webpackChunkName: "404" */'../views/404.vue')
    },
    {
        path: '/403',
        name: '403',
        meta: {
            title: '找不到页面'
        },
        component: () => import(/* webpackChunkName: "403" */'../views/403.vue')
    },
    {
        path: '/index',
        component: layout,
        children: [{
            name: 'index',
            path: '/index',
            component: () => import(/* webpackChunkName: "productIndex" */'@/views/index.vue')
        }]
    },
    {
        path: '/product',
        component: layout,
        children: [{
            name: 'productIndex',
            path: 'index',
            component: () => import(/* webpackChunkName: "productIndex" */'@/views/product/index.vue')
        }]
    },
    {
        path: '/reservation',
        component: layout,
        children: [{
            name: 'reservationRuleIndex',
            path: 'rule/index',
            component: () => import(/* webpackChunkName: "reservationRuleIndex" */'@/views/reservation/rule/index.vue')
        }]
    },
    {
        path: '/reservation',
        component: layout,
        children: [{
            name: 'reservationSortPriceIndex',
            path: 'sortprice/index',
            component: () => import(/* webpackChunkName: "reservationSortPriceIndex" */'@/views/reservation/sortprice/index.vue')
        }]
    },
    {
        path: '/reservation',
        component: layout,
        children: [
            {
                name: 'reservationIndex',
                path: 'index',
                component: () => import(/* webpackChunkName: "reservationIndex" */'@/views/reservation/index/index.vue')
            },
            {
                name: 'reservationEdit',
                path: 'edit/:id',
                component: () => import(/* webpackChunkName: "reservationEdit" */'@/views/reservation/index/edit.vue')
            },
            {
                name: 'reservationFix',
                path: 'fix/:id',
                component: () => import(/* webpackChunkName: "reservationFix" */'@/views/reservation/index/fix.vue')
            },
            {
                name: 'reservationInfo',
                path: 'info/:id',
                component: () => import(/* webpackChunkName: "reservationInfo" */'@/views/reservation/index/info.vue')
            },
            {
                name: 'reservationPay',
                path: 'pay/:id',
                component: () => import(/* webpackChunkName: "reservationPay" */'@/views/reservation/index/pay.vue')
            }
        ]
    },
    {
        path: '/property',
        component: layout,
        children: [{
            name: 'propertyIndex',
            path: 'index',
            component: () => import(/* webpackChunkName: "propertyIndex" */'@/views/property/index.vue')
        }]
    },
    {
        path: '/property',
        component: layout,
        children: [{
            name: 'propertyRecordIndex',
            path: 'record/index',
            component: () => import(/* webpackChunkName: "propertyRecordIndex" */'@/views/property/record/index.vue')
        }]
    },
    {
        path: '/staff',
        component: layout,
        children: [{
            name: 'stevedoresIndex',
            path: 'stevedores/index',
            component: () => import(/* webpackChunkName: "stevedoresIndex" */'@/views/staff/stevedores/index.vue')
        }, {
            name: 'adminUserIndex',
            path: 'adminUser/index',
            component: () => import(/* webpackChunkName: "adminUserIndex" */'@/views/staff/adminUser/index.vue')
        }]
    },
    {
        path: '/paymentRecord',
        component: layout,
        children: [{
            name: 'paymentRecordIndex',
            path: 'index',
            component: () => import(/* webpackChunkName: "paymentRecordIndex" */'@/views/paymentRecord/index.vue')
        },{
            name: 'paymentRecordBill',
            path: 'bill',
            component: () => import(/* webpackChunkName: "paymentRecordBill" */'@/views/paymentRecord/bill.vue')
        }]
    },
    {
        path: '/tenant',
        component: layout,
        children: [{
            name: 'tenantIndex',
            path: 'index',
            component: () => import(/* webpackChunkName: "stevedoresIndex" */'@/views/tenant/index.vue')
        }]
    },
    {
        path: '/log',
        component: layout,
        children: [{
            name: 'logIndex',
            path: 'index',
            component: () => import(/* webpackChunkName: "stevedoresIndex" */'@/views/operationLog/index.vue')
        }]
    },
    {
        path: '/globalconfig',
        component: layout,
        children: [{
            name: 'globalconfigIndex',
            path: 'index',
            component: () => import(/* webpackChunkName: "globalconfigIndex" */'@/views/globalconfig/index.vue')
        }]
    },

    {
        path: '/stockIn',
        component: layout,
        children: [{
            name: 'stockInOrder',
            path: 'order',
            component: () => import(/* webpackChunkName: "stockInOrder" */'@/views/stockIn/order.vue')
        }, {
            name: 'stockInAdd',
            path: 'add',
            component: () => import(/* webpackChunkName: "stockInAdd" */'@/views/stockIn/add.vue')
        }, {
            name: 'stockInPay',
            path: 'pay/:stockInNo',
            component: () => import(/* webpackChunkName: "stockInAdd" */'@/views/stockIn/pay.vue')
        }, {
            name: 'stockInEdit',
            path: 'edit/:stockInNo',
            component: () => import(/* webpackChunkName: "stockInEdit" */'@/views/stockIn/edit.vue')
        }]
    },

    {
        path: '/stockOut',
        component: layout,
        children: [{
            name: 'stockOutOrder',
            path: 'order',
            component: () => import(/* webpackChunkName: "stockOutIndex" */'@/views/stockOut/order.vue')
        }, {
            name: 'stockOutAdd',
            path: 'add',
            component: () => import(/* webpackChunkName: "stockOutAdd" */'@/views/stockOut/add.vue')
        }, {
            name: 'stockOutPay',
            path: 'pay/:stockOutNo',
            component: () => import(/* webpackChunkName: "stockOutAdd" */'@/views/stockOut/pay.vue')
        }, {
            name: 'stockOutEdit',
            path: 'edit/:stockOutNo',
            component: () => import(/* webpackChunkName: "stockOutEdit" */'@/views/stockOut/edit.vue')
        }]
    },

]

export default routes;