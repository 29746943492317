<template>
    <router-view />
</template>

<script>
export default {};
</script>

<style>
@import "./assets/css/main.css";
</style>
